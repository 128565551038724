<template>
  <div>
    <portal to="subheader_actions">
      <b-button class="mr-2 openModalFormColor" @click="onSubmit">
        Guardar
      </b-button>
    </portal>

    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div class="card__document-signature">
              <div
                class="aspect-ratio-box"
                v-if="showDocumentSign"
                :style="styleDocument"
              >
                <div class="aspect-ratio-box-inside" :style="styleDocument">
                  <div style="position: relative">
                    <movable
                      class="signature1"
                      :posLeft="posLeft1"
                      :posTop="posTop1"
                      :grid="40"
                      v-on:complete="completeMovedSignature1"
                      ><span>Firma Administrador</span></movable
                    >
                    <movable
                      class="signature2"
                      :posLeft="posLeft2"
                      :posTop="posTop2"
                      v-on:complete="completeMovedSignature2"
                      :grid="20"
                      ><span>Firma Empleado</span></movable
                    >
                  </div>
                </div>
              </div>
              <div class="ml-5">
                <file-upload
                  name="file-one"
                  class="btn btn-primary mb-5"
                  :disabled="sendingFiles"
                  :extensions="extensions"
                  :accept="accept"
                  :multiple="false"
                  @input-file="uploadSingleFile"
                  ref="upload-one"
                >
                  <i class="fa fa-plus"></i>
                  Seleccione un archivo PDF
                </file-upload>

                <form class="full" id="formSubproject" ref="formSubproject">
                  <b-alert
                    show
                    variant="danger"
                    v-bind:key="error"
                    v-for="error in errors"
                    >{{ error }}</b-alert
                  >
                  <trazo-form-input
                    id="input-description"
                    v-model="description"
                    label="Descripción"
                    name="description"
                    :required="true"
                    labelPosition="top"
                    type="text"
                  />
                  <trazo-form-input
                    id="input-abbrev"
                    v-model="abbrev"
                    label="Abreviatura"
                    name="abbrev"
                    :required="true"
                    labelPosition="top"
                    type="text"
                  />
                  <b-form-checkbox
                    v-model="require_enterprise_sign"
                    value="true"
                    unchecked-value="false"
                  >
                    Requiere firma de representante legal
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="require_employee_sign"
                    value="true"
                    unchecked-value="false"
                  >
                    Requiere firma de empleado
                  </b-form-checkbox>
                </form>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
$WIDTH_SIGN: 100px;
$HEIGHT_SIGN: 34px;

.card__document-signature {
  display: flex;
}

.document-signature__column {
  margin-left: 2rem;
}

.signature1 {
  width: $WIDTH_SIGN;
  height: $HEIGHT_SIGN;
  background: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  text-align: center;
  color: white;
}
.signature2 {
  width: $WIDTH_SIGN;
  height: $HEIGHT_SIGN;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  text-align: center;
}
.aspect-ratio-box {
  overflow: hidden;
  background: #ddd;
  position: relative;
  width: 100%;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TrazoFormInput from "@/view/content/TrazoFormInput";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";

const WIDTH_SIGN = 100;
const HEIGHT_SIGN = 34;

export default {
  data() {
    return {
      errors: [],
      description: "",
      abbrev: "",
      require_employee_sign: false,
      require_enterprise_sign: false,
      showDocumentSign: false,
      signature1: {},
      signature2: {},
      sendingFiles: false,
      accept: ".pdf",
      extensions: ["pdf"],
      currentFile: {},
      currentDocument: {},
      backgroundImage: null,
      styleDocument: {},

      heightDocument: 0,
      widthDocument: 0,

      id: 0
    };
  },
  computed: {
    posTop1() {
      if (this.id) {
        return this.signature1.top;
      }
      return this.heightDocument - WIDTH_SIGN - 40;
    },
    posTop2() {
      if (this.id) {
        return this.signature2.top;
      }
      return this.heightDocument - WIDTH_SIGN - 40;
    },
    posLeft1() {
      if (this.id) {
        return this.signature1.left;
      }
      return 20;
    },
    posLeft2() {
      if (this.id) {
        return this.signature2.left;
      }
      return this.widthDocument - WIDTH_SIGN - 40;
    },
    rows() {
      return this.items.length;
    }
  },
  methods: {
    // form
    closeModal() {
      this.resetForm();
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {});
      this.fv.off("core.form.invalid", () => {});
      this.fv.resetForm();
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        const saveFunction = this.currentDocument?.id
          ? ApiService.patch
          : ApiService.post;
        const objeSaved = {
          description: this.description.trim(),
          abbrev: this.abbrev.trim(),
          require_employee_sign: this.require_employee_sign,
          require_enterprise_sign: this.require_enterprise_sign,
          signature_enterprise_position: [
            parseFloat(this.signature1.left).toFixed(2),
            parseFloat(this.signature1.top).toFixed(2),
            parseFloat(this.signature1.left + WIDTH_SIGN).toFixed(2),
            parseFloat(this.signature1.top + WIDTH_SIGN).toFixed(2)
          ].map(el => parseFloat(el)),
          signature_employee_position: [
            parseFloat(this.signature2.left).toFixed(2),
            parseFloat(this.signature2.top).toFixed(2),
            parseFloat(this.signature2.left + WIDTH_SIGN).toFixed(2),
            parseFloat(this.signature2.top + WIDTH_SIGN).toFixed(2)
          ].map(el => parseFloat(el))
        };
        this.errors = [];
        this.sending = true;
        saveFunction(
          `api/document/${
            this.currentDocument.id ? this.currentDocument.id + "/" : ""
          }`,
          objeSaved
        )
          .then(({ data }) => {
            this.openToast({
              message: `Se ha ${
                this.edit ? "editado" : "creado"
              } el item correctamente`,
              object: {
                title: `Éxito`,
                variant: "success",
                solid: true
              }
            });
            this.openToast(this.edit ? "updated" : "created", data);
            this.$router.push({ name: "maintenances_document_list" });
            this.sending = false;
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            try {
              Object.entries(data).forEach(object => {
                this.errors.push(object[1].join(" - "));
                this.fv.updateFieldStatus(object[0], "Invalid");
              });
            } finally {
              this.openToast({
                message: "Ha ocurrido un error",
                object: {
                  title: `Error`,
                  variant: "danger",
                  solid: true
                }
              });
            }
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.openToast({
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          abbrev: {
            validators: {
              notEmpty: {
                message: "La abreviatura es obligatoria"
              }
            }
          },
          description: {
            validators: {
              notEmpty: {
                message: "La description es obligatoria"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    },
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    // end-form
    uploadSingleFile(file) {
      this.currentFile = file;
      const formData = new FormData();
      formData.append("example_file", file.file, file.name);

      const saveFunction = this.id ? ApiService.patch : ApiService.post;
      saveFunction(`/api/document/${this.id ? this.id + "/" : ""}`, formData, {
        headers: {
          "Content-Type": " multipart/form-data"
        }
      }).then(({ data }) => {
        this.showDocumentSign = true;
        this.setData(data.id);
      });
    },
    completeMovedSignature1(val) {
      this.signature1 = val.css;
    },
    completeMovedSignature2(val) {
      this.signature2 = val.css;
    },
    setData(id = false) {
      ApiService.get(`api/document/${this.id || id}`).then(({ data }) => {
        this.showDocumentSign = true;
        this.currentDocument = data;
        this.backgroundImage = data.example_image;
        this.styleDocument = {
          "background-image": `url(${this.backgroundImage})`,
          "max-width": `${data.size[2]}px`,
          height: `${data.size[3]}px`
        };
        this.heightDocument = data.size[3];
        this.widthDocument = data.size[2];
        if (data.signature_enterprise_position.length !== 0)
          this.signature1 = {
            left: data.signature_enterprise_position[0],
            top: data.signature_enterprise_position[1]
          };
        else {
          this.signature1 = {
            left: 20,
            top: this.heightDocument - HEIGHT_SIGN - 40
          };
        }

        if (data.signature_employee_position.length !== 0)
          this.signature2 = {
            left: data.signature_employee_position[0],
            top: data.signature_employee_position[1]
          };
        else {
          this.signature2 = {
            left: this.widthDocument - WIDTH_SIGN - 40,
            top: this.heightDocument - HEIGHT_SIGN - 40
          };
        }

        this.description = data.description;
        this.abbrev = data.abbrev;
        this.require_employee_sign = data.require_employee_sign;
        this.require_enterprise_sign = data.require_enterprise_sign;
      });
    }
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Firma de Documentos" }
    ]);

    if (this.$router.history.current.params?.id) {
      this.id = this.$router.history.current.params?.id;
      this.setData();
    }
  },
  components: {
    TrazoFormInput
  }
};
</script>
